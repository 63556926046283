<template>
  <div class="content-row">
    <div class="content-layout-fixer">
      <VueHeadline class="products-headline" level="1" weightLevel="3"
        >Güncel Ürün ve Fiyat Listesi</VueHeadline
      >
      <div class="table-top-wrapper">
        <div class="table-info">
          <p>{{ priceListInfoText }}</p>
          <p>
            Son Güncelleme Tarihi: <b>{{ updateDate }}</b>
          </p>
        </div>
        <div class="search-wrapper">
          <i data-v-4c13b0be="" data-v-b4ccab6e="" class="iconfont iconsearch"></i>
          <input placeholder="Ara" class="search-input" type="text" @input="search($event)" />
        </div>
      </div>
      <vue-table-dynamic :params="params" ref="table" @sort-change="sortChange">
        <template v-slot:column-0="{ props }">
          <img class="product-img" :src="props.cellData" />
        </template>
      </vue-table-dynamic>
    </div>
  </div>
</template>
<script>
import VueTableDynamic from 'vue-table-dynamic';
import { Pladis } from '@/services/Api/index.js';
import dateUtils from '@/mixins/dateUtils.js';
import VueHeadline from '@/components/shared/VueHeadline/VueHeadline.vue';

import '@/assets/ulker/Roboto-Regular-normal.js';
export default {
  name: 'List',
  components: {
    VueTableDynamic,
    VueHeadline,
  },
  mixins: [dateUtils],
  props: {
    partnerId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      params: {
        data: [
          [
            'Ürün Resmi',
            'Ürün Adı',
            'Tavsiye Satış Fiyatı',
            'Alış Fiyatı',
            'Ön Sipariş İçeriği',
            'Kategori',
            'Marka',
            'Ürün Kodu',
          ],
        ],
        header: 'row',
        headerHeight: 80,
        rowHeight: 111,
        border: true,
        search: this.search,
        sort: [1, 2, 3, 4, 5, 6, 7],
        pagination: true,
        pageSize: 50,
        converting: false,
      },
      priceListInfoText: '',
      createDate: '',
    };
  },
  mounted() {
    this.getPriceList();
  },
  computed: {
    updateDate() {
      return this.toLocaleDateTimeString(this.createDate);
    },
  },
  methods: {
    getPriceList() {
      Pladis.getProductsAndPriceList(this.partnerId).then(response => {
        if (response.data && response.data.Data) {
          let data = response.data.Data.priceList.map(item => {
            let priceWithSpace = `₺${Number(item.price.split(' ')[0])
              .toFixed(2)
              .replace('.', ',')}
                ${item.price.split(' ')[1]}
                ${item.price.split(' ')[2]}`;
            let price = priceWithSpace.replace(/\s/g, '');
            let retailPrice = `₺${item.reatilPrice.toFixed(2).replace('.', ',')}`;
            let unit = `${item.unit} adet`;
            return [
              item.image,
              item.description,
              retailPrice,
              price,
              unit,
              item.category,
              item.product,
              item.productCode,
            ];
          });
          this.params.data = [...this.params.data, ...data];
          this.priceListInfoText = response.data.Data.priceListInfoText;
          this.createDate = response.data.Data.createDate;
          this.$emit('createDate', this.toLocaleDateTimeString(this.createDate));
          this.fixTable();
          this.sortChange(1, 'ascending');
          this.$emit('getPriceList', this.params.data);
          this.$emit('getPriceListInfoText', this.priceListInfoText);
        }
      });
    },
    sortChange(index, value) {
      let head = this.params.data[0];
      if (value === 'ascending' && index !== 2 && index !== 5) {
        this.params.data.shift();
        this.params.data
          .sort(function(a, b) {
            return (
              isFinite(a[index][0]) - isFinite(b[index][0]) ||
              a[index].toString().localeCompare(b[index].toString(), undefined, {
                numeric: true,
                sensitivity: 'base',
              })
            );
          })
          .unshift(head);
      }
      if (value === 'descending' && index !== 2 && index !== 5) {
        this.params.data.shift();
        this.params.data
          .sort(function(a, b) {
            return (
              isFinite(a[index][0]) - isFinite(b[index][0]) ||
              b[index].toString().localeCompare(a[index].toString(), undefined, {
                numeric: true,
                sensitivity: 'base',
              })
            );
          })
          .unshift(head);
      }
    },
    search(e, included, excluded, transform) {
      let searchValue = e.target.value;
      if (!(this.$refs.table.tableData && this.$refs.table.tableData.rows)) return;
      searchValue = String(searchValue);
      this.$refs.table.tableData.rows.forEach(row => {
        if (row && row.cells) {
          if (!searchValue) {
            return (row.show = true);
          }
          let matched = row.cells.some((cell, index) => {
            const transformItem = Array.isArray(transform)
              ? transform.find(
                  item => item && item.column === index && typeof item.method === 'function',
                ) || null
              : null;
            const cellData = transformItem
              ? transformItem.method(cell.data, index, { ...row })
              : cell.data;
            return this.removeTurkishChars(String(cellData))
              .toLocaleLowerCase()
              .includes(this.removeTurkishChars(searchValue.toLocaleLowerCase()));
          });
          row.show = !!matched;
        }
      });
      this.$refs.table.updateActivatedRows();
      this.$refs.table.$nextTick(this.$refs.table.updatePagination);
    },
    removeTurkishChars(text) {
      let returnText = text
        .replace(/ı/g, 'i')
        .replace(/ö/g, 'o')
        .replace(/ü/g, 'u')
        .replace(/ç/g, 'c')
        .replace(/ş/g, 's')
        .replace(/I/g, 'i')
        .replace(/İ/g, 'I')
        .replace(/Ö/g, 'O')
        .replace(/Ü/g, 'U')
        .replace(/Ç/g, 'C')
        .replace(/Ş/g, 'S')
        .toLowerCase();
      return returnText;
    },
    fixTable() {
      let sizeText = document.querySelector('.size-text');
      sizeText.textContent = sizeText.textContent.replace('/page', ' ürün / sayfa');
      let sizeItems = document.querySelectorAll('.size-item');
      sizeItems.forEach(i => {
        i.textContent = i.textContent.replace('/page', ' ürün / sayfa');
        i.addEventListener('click', () => {
          sizeText.textContent = i.textContent.match(/\d+/)[0] + ' ürün / sayfa';
        });
      });
      let els = document.querySelectorAll('.sort-btns');
      for (var i = 0; i < els.length; i++) {
        els[i].addEventListener('click', e => {
          for (var i = 0; i < els.length; i++) {
            els[i].classList.remove('active');
          }
          e.target.classList.add('active');
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@mixin flex-center {
  display: flex;
  align-items: center;
}
.product-img {
  max-height: 100%;
}
</style>

<style>
@media print {
  @page {
    margin: 0;
  }
  body * {
    visibility: hidden;
  }
  #priceList * {
    visibility: visible;
  }
  #priceList {
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
  }
}
.table-sort .sort-btns {
  border-width: 10px !important;
}
.table-sort .sort-ascending {
  top: -7px !important;
}
.table-sort .sort-descending {
  top: 15px !important;
}
.v-table-tools {
  margin-top: 20px;
  justify-content: flex-end !important;
}
.tools-search {
  width: 400px !important;
  margin-right: 0 !important;
}
.v-table-row {
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  color: #79838e;
  background-color: #f8f9f9 !important;
}
.v-table-row.is-header {
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  font-weight: 600 !important;
  background-color: #e7e7e7 !important;
}
.table-cell:first-child {
  justify-content: center !important;
}
.table-cell {
  padding: 5px 10px !important;
  text-align: center !important;
  justify-content: center;
}
.table-cell-content {
  white-space: initial !important;
  text-align: center !important;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.table-cell-inner {
  display: flex !important;
  justify-content: space-between;
}
.v-table-row,
.v-table:before {
  border-bottom: 1px solid #b4c2d3 !important;
}
.v-table.v-show-border {
  border-top: 1px solid #b4c2d3 !important;
}
.table-cell.v-show-border,
.v-table-left-line {
  border-left: 1px solid #b4c2d3 !important;
}
.v-table-right-line {
  border-right: 1px solid #b4c2d3 !important;
}
.pagination-size,
.page-item {
  font-size: 14px !important;
  height: 30px !important;
  border-color: #b4c2d3 !important;
}
.vue-input {
  border-color: #b4c2d3 !important;
}
.vue-input:focus {
  border-color: rgb(121, 131, 142) !important;
}
.sort-btns.active.sort-ascending {
  border-bottom-color: #046fdb !important;
}
.sort-btns.active.sort-descending {
  border-top-color: #046fdb !important;
}
.table-top-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #79838e;
  margin-bottom: 20px;
  margin-top: 25px;
}
.table-info {
  margin-top: 5px;
  font-size: 15px;
}
.table-info p:first-child {
  margin-bottom: 5px;
}
.search-wrapper {
  display: flex;
  justify-content: flex-end;
}
.search-input {
  -webkit-appearance: none;
  background-color: transparent;
  background-image: none;
  border-radius: 2px;
  border: 1px solid;
  box-sizing: border-box;
  display: inline-block;
  font-size: 14px;
  height: 28px;
  line-height: 28px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s ease;
  width: 400px;
  height: 47px;
  border-color: #b4c2d3;
  color: #79838e;
  padding-left: 30px;
}
.search-input::placeholder {
  color: #b4c2d3;
}
.search-input:focus {
  border-color: #79838e;
}
.iconsearch {
  position: relative;
  left: 30px;
  top: 10px;
  color: #b4c2d3;
}
</style>
